// import React from 'react'

// const WiseHome = () => {
//     return (
//         <div>

//             <div>
// <img className="object-cover w-full rounded-lg h-96 "
//     src="/Mockup wise home.png"
//     alt="" />
// <h2 className="mt-4 text-2xl font-semibold text-gray-800 capitalize ">Wise Home</h2>
// <p className="mt-2 text-lg tracking-wider text-rose-500 uppercase  ">Aplicativo</p>
//             </div>



//         </div>
//     )
// }

// export default WiseHome


// import React from 'react'

// const VBank = () => {
//     return (
//         <div>

//             




//         </div>
//     )
// }

// export default VBank


import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';


export default function MaxWidthDialog() {
    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <React.Fragment>





            <div onClick={handleClickOpen} >

                <img className="object-cover w-full rounded-lg h-96 cursor-pointer"
                    src="/Mockup wise home.png"
                    alt="" />
                <h2 className="mt-4 text-2xl font-semibold text-gray-800 capitalize ">Wise Home</h2>
                <p className="mt-2 text-lg tracking-wider text-rose-500 uppercase  ">Aplicativo</p>

            </div>



            <Dialog
                fullWidth="true"
                maxWidth="lg"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    
                <span className="text-1xl font-semibold text-gray-800 lg:text-3xl ">Wise Home</span>
                    </DialogTitle>
                <DialogContent dividers>


                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} >



                            <div className="overflow-hidden bg-cover rounded-lg  h-96 group width: 100%;"
                                style={{ backgroundImage: `url("/Mockup wise home.png")` }}>

                            </div>

                        </Grid>


                        <Grid item xs={12} sm={8}>
                            <Typography variant="body1" gutterBottom>
                            Desenvolvimento de um aplicativo para plataformas Android e IOS que se conecta com a placa de automação residencial da empresa Wise Home, contendo inteligência artificial para controle por voz e conectividade com Alexa.



                            </Typography>

                            <Stack direction="row" flexWrap={'wrap'} >




                                <Chip sx={{ m: 1 }} label="Aplicativo IOS " variant="outlined" />
                                <Chip sx={{ m: 1 }} label="Android" variant="outlined" />
                                <Chip sx={{ m: 1 }} label="Design e layout responsivo" variant="outlined" />
                                <Chip sx={{ m: 1 }} label="C# Xamarim " variant="outlined" />
                                <Chip sx={{ m: 1 }} label="Inteligencia artificial " variant="outlined" />



                            </Stack>

                            <Stack direction="row" spacing={1} >

                            </Stack>

                        </Grid>



                    </Grid>





                </DialogContent>
                <DialogActions>
                <button variant="contained" onClick={handleClose} className=" border cursor-pointer border-transparent text-base font-medium rounded text-white bg-rose-600 hover:bg-rose-700 md:py-3 md:text-lg md:px-4">Fechar</button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}