import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';


export default function MaxWidthDialog() {
  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  return (
    <React.Fragment>





      <div onClick={handleClickOpen} >
        <div className="overflow-hidden bg-cover rounded-lg cursor-pointer h-96 group"
          style={{ backgroundImage: `url("/mockup Scan do brasil.png")` }}>

        </div>


        <h2 className="mt-4 text-2xl font-semibold text-gray-800 capitalize ">Scan</h2>
        <p className="mt-2 text-lg tracking-wider text-rose-500 uppercase  ">Website/Ecommerce</p>
      </div>



      <Dialog
        fullWidth="true"
        maxWidth="lg"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle ><span className="text-1xl font-semibold text-gray-800 lg:text-3xl ">Scan do Brasil</span></DialogTitle>
        <DialogContent dividers>


          <Grid container spacing={4}>
            <Grid item xs={12} sm={4} >
            


              <div className="overflow-hidden bg-cover rounded-lg  h-96 group width: 100%;"
                style={{ backgroundImage: `url("/mockup Scan do brasil.png")` }}>

              </div>

            </Grid>
           

            <Grid item xs={12} sm={8}>
              <Typography variant="body1" gutterBottom>
                Desenvolvido um e-commerce e um sistema online de controle de estoque, pedidos, clientes, orçamentos e vendas online. A Scan do Brasil já está a mais de 22 anos no mercado de automação industrial, o e-commerce desenvolvido pelo time Here IT contribui para o posicionamento tecnológico da empresa.

              </Typography>

              <Stack direction="row" flexWrap={'wrap'} >








                <Chip  sx={{m:1}}label="SQL service" variant="outlined" />
                <Chip sx={{m:1}} label="Desenvolvimento Web C#" variant="outlined" />
                <Chip  sx={{m:1}}label="Design e layout responsivo" variant="outlined" />
                <Chip  sx={{m:1}}label="E-commerce " variant="outlined" />
                <Chip  sx={{m:1}}label="E-commerce " variant="outlined" />
                <Chip  sx={{m:1}}label="Site" variant="outlined" />


              </Stack>
              
              

            </Grid>
            


          </Grid>

         



        </DialogContent>
        <DialogActions>
          <button variant="contained" onClick={handleClose} className=" border cursor-pointer border-transparent text-base font-medium rounded text-white bg-rose-600 hover:bg-rose-700 md:py-3 md:text-lg md:px-4">Fechar</button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}