

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';


export default function MaxWidthDialog() {
    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <React.Fragment>





            <div onClick={handleClickOpen} >


            <img className="object-cover w-full rounded-lg h-96 cursor-pointer"
                    src="Mockup Loígico.png"
                    alt="" />
                <h2 className="mt-4 text-2xl font-semibold text-gray-800 capitalize ">Estudio didático</h2>
                <p className="mt-2 text-lg tracking-wider text-rose-500 uppercase  ">Aplicativo</p>


                

            </div>



            <Dialog
                fullWidth="true"
                maxWidth="lg"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                <span className="text-1xl font-semibold text-gray-800 lg:text-3xl ">Estudo Didático</span>
                   </DialogTitle>
                <DialogContent dividers>


                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} >



                            <div className="overflow-hidden bg-cover rounded-lg  h-96 group width: 100%;"
                                style={{ backgroundImage: `url("/Mockup Loígico.png")` }}>

                            </div>

                        </Grid>


                        <Grid item xs={12} sm={8}>
                            <Typography variant="body1" gutterBottom>
                            Trazendo mais dinamismo aos produtos fornecidos pela empresa, desenvolvemos uma plataforma utilizando realidade aumentada trazendo um novo prisma tecnológico ao produto.



                               




                            </Typography>

                            <Stack direction="row" flexWrap={'wrap'} >










                                <Chip sx={{ m: 1 }} label="Aplicativo IOS " variant="outlined" />
                                <Chip sx={{ m: 1 }} label="Android" variant="outlined" />
                                <Chip sx={{ m: 1 }} label="Modelagem 3D" variant="outlined" />
                                <Chip sx={{ m: 1 }} label="C#  " variant="outlined" />



                            </Stack>

                            <Stack direction="row" spacing={1} >

                            </Stack>

                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                <button variant="contained" onClick={handleClose} className=" border cursor-pointer border-transparent text-base font-medium rounded text-white bg-rose-600 hover:bg-rose-700 md:py-3 md:text-lg md:px-4">Fechar</button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}